<template>
    <div class="right-menu shipping-right" v-loading.fullscreen.lock="paymentMethodsLoader">
        <subHeader pageName="PAYMENT METHODS" />

        <tutorialsAction />

        <!-- Payment Block -->
        <div class="paymentSection">
            <div class="db-container">
                <div class="paymentMainBlock">

                    <!-- Cash On Delivery Starts Here-->
                    <div class="paymentMethodWrap" id='COD-step'>
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="paymentHeading">
                                    <h4>Cash on Delivery</h4>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="actionSwitch">
                                    <label class="switch" id="COD-step-status">
                                        <input type="checkbox" v-model="cashOnDelivery.isActive" :disabled="tourIsActive" @change="toggleProceedableHandle('COD')">
                                        <span class="slider"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Cash On Delivery Ends Here-->

                    <!-- Alfalah Payment Gateway Starts Here-->
                    <div class="paymentMethodWrap" id='Alfalah-step'>
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="paymentHeading">
                                    <h4>Alfalah Payment Gateway</h4>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="actionSwitch">
                                    <label class="switch" id="alfalah-step-status">
                                        <input type="checkbox" v-model="alfalahPaymenthMethod.isActive" :disabled="tourIsActive" @change="toggleProceedableHandle('alfalah')">
                                        <span class="slider"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ValidationObserver ref="alfalahObserver">
                            <div class="bankingFormField" :class="alfalahPaymenthMethod.isActive ? 'expandForm' : ''" id="Alfalah-form">
                                <form @submit.prevent="validate('alfalah')">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <ValidationProvider name="merchant username" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="alfalah-merchant-username">Merchant Username <span>*</span></label>
                                                    <input type="text" id="alfalah-merchant-username" v-model="alfalahPaymentGateway.merchantUsername" :disabled="tourIsActive">
                                                    <span class="text-danger" >{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider name="merchant password" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="alfalah-merchant-password">Merchant Password <span>*</span></label>
                                                    <input type="password" id="alfalah-merchant-password" autocomplete="true" v-model="alfalahPaymentGateway.merchantPassword" :disabled="tourIsActive">
                                                    <span class="text-danger" >{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider name="channel id" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="alfalah-channel-id">Channel ID <span>*</span></label>
                                                    <input type="text" id="alfalah-channel-id" v-model="alfalahPaymentGateway.merchantChannelId" :disabled="tourIsActive">
                                                    <span class="text-danger" >{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider name="merchant id" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="alfalah-merchant-id">Merchant ID <span>*</span></label>
                                                    <input type="text" id="alfalah-merchant-id" v-model="alfalahPaymentGateway.merchantId" :disabled="tourIsActive">
                                                    <span class="text-danger" >{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider name="store id" rules="required" v-slot="{ errors }">
                                            <div class="bankInputField">
                                                <label for="alfalah-store-id">Store ID <span>*</span></label>
                                                <input type="text" id="alfalah-store-id" v-model="alfalahPaymentGateway.merchantStoreId" :disabled="tourIsActive">
                                                <span class="text-danger" >{{ errors[0] }}</span>
                                            </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider name="request hash" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="alfalah-merchant-hash">Merchant Hash <span>*</span></label>
                                                    <input type="text" id="alfalah-merchant-hash" v-model="alfalahPaymentGateway.merchantHash" :disabled="tourIsActive">
                                                    <span class="text-danger" >{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider name="key 1" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="alfalah-key-one">Key 1<span>*</span></label>
                                                    <input type="text" id="alfalah-key-one" v-model="alfalahPaymentGateway.merchantKeyOne" :disabled="tourIsActive">
                                                    <span class="text-danger" >{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider name="key 2" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="alfalah-key-two">Key 2<span>*</span></label>
                                                    <input type="text" id="alfalah-key-two" v-model="alfalahPaymentGateway.merchantKeyTwo" :disabled="tourIsActive">
                                                    <span class="text-danger" >{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6" >
                                            <ValidationProvider name="return url" rules="required" v-slot="{ errors }">
                                            <div class="bankInputField">
                                                <label for="merchantReturnUrl">Return Url <span>*</span></label>
                                                <input type="text" id="merchantReturnUrl" v-model="alfalahPaymentGateway.merchantreturnUrl" disabled>
                                                <span class="text-danger" >{{ errors[0] }}</span>
                                            </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="paymentSaveBtn">
                                                <loaderBtn v-if="alfalahLoader"/>
                                                <Button id="alfalah-save-btn" :disabled="tourIsActive" v-else>Save</Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ValidationObserver>
                    </div>
                    <!-- Alfalah Payment Gateway Ends Here-->

                    <!-- Stripe Payment Gateway Starts Here-->
                    <div class="paymentMethodWrap" id='stripe-step'>
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="paymentHeading">
                                    <h4>Stripe Payment Gateway</h4>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="actionSwitch">
                                    <label class="switch" id="stripe-step-status">
                                        <input type="checkbox" v-model="stripePaymentMethod.isActive" :disabled="tourIsActive" @change="toggleProceedableHandle('stripe')">
                                        <span class="slider"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ValidationObserver ref="stripeObserver">
                            <div class="bankingFormField" :class="stripePaymentMethod.isActive ? 'expandForm' : ''" id="stripe-form">
                                <form @submit.prevent="validate('stripe')">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <ValidationProvider name="publishable key" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="stripe-publishable-key">Publishable Key <span>*</span></label>
                                                    <input type="text" id="stripe-publishable-key" v-model="stripePaymentGateway.publishableKey" :disabled="tourIsActive">
                                                    <span class="text-danger" >{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider name="secret key" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="stripe-secret-key">Secret Key <span>*</span></label>
                                                    <input type="text" id="stripe-secret-key" v-model="stripePaymentGateway.secretKey" :disabled="tourIsActive">
                                                    <span class="text-danger" >{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="paymentSaveBtn stripe">
                                                <div class="paymentNote">
                                                    <p class="fieldSeoMessage">
                                                        <span style="display: inline-flex;margin-right: 5px;">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                            <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                        </svg> 
                                                        </span>
                                                        Orders must meet a minimum total of 50 cents in USD to use Stripe as the payment method
                                                    </p>
                                                </div>
                                                <loaderBtn v-if="stripeLoader"/>
                                                <Button id="stripe-save-btn" :disabled="tourIsActive" v-else>Save</Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ValidationObserver>
                    </div>
                    <!-- Stripe Payment Gateway Ends Here-->

                    <!-- Bank Transfer Starts Here -->
                    <div class="paymentMethodWrap" id='bank-transfer-step'>
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="paymentHeading">
                                    <h4>Bank Transfer</h4>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="actionSwitch">
                                    <label class="switch" id="bank-transfer-step-status">
                                        <input type="checkbox" v-model="bankTransferPaymentMethod.isActive" :disabled="tourIsActive" @change="toggleProceedableHandle('bank-transfer')">
                                        <span class="slider"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ValidationObserver ref="bankTransferObserver">
                            <div class="bankingFormField" :class="bankTransferPaymentMethod.isActive ? 'expandForm' : ''" id="bank-transfer-form">
                                <form @submit.prevent="validate('bankTransfer')">
                                    <div class="bankPaymentMethod" v-for="(bank,index) in bankTransferBanksList" :key="index">
                                        <div class="bankPaymentHeader">
                                            <h3>Bank {{ index + 1 }}</h3>
                                            <div class="bankPaymentOptions">
                                                <div class="actionSwitch">
                                                    <label class="switch" id="bank-transfer-step-status">
                                                        <input type="checkbox" v-model="bank.isActive" :disabled="tourIsActive" @change="bankStatusChangeHandle(bank,index)">
                                                        <span class="slider"></span>
                                                    </label>
                                                </div>
                                                <button type="button" class="delPaymentField" @click="removeBankHandle(bank,index)">
                                                    <svg height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg"><path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4v-24h-24v24zm26-30h-7l-2-2h-10l-2 2h-7v4h28v-4z"/><path d="M0 0h48v48h-48z" fill="none"/></svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="row" :class="bank.isActive == 1 ? 'expand' : ''">
                                            <div class="col-md-6">
                                                <ValidationProvider name="bank name" rules="required" :vid="'bank-name_'+index" v-slot="{ errors }">
                                                    <div class="bankInputField">
                                                        <label :for="`bankName+${index}`">Bank Name <span>*</span></label>
                                                        <input type="text" :id="`bankName+${index}`" v-model="bank.bank_name" :disabled="tourIsActive">
                                                        <span class="text-danger" >{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-md-6">
                                                <ValidationProvider name="account title" rules="required" :vid="'bank-title_'+index" v-slot="{ errors }">
                                                    <div class="bankInputField">
                                                        <label :for="`accountTitle+${index}`">Account Title <span>*</span></label>
                                                        <input type="text" :id="`accountTitle+${index}`" v-model="bank.account_title" :disabled="tourIsActive">
                                                        <span class="text-danger" >{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-md-6">
                                                <ValidationProvider name="account no." rules="required" :vid="'account-no_'+index" v-slot="{ errors }">
                                                    <div class="bankInputField">
                                                        <label :for="`accountNo+${index}`">Account No. <span>*</span></label>
                                                        <input type="text" :id="`accountNo+${index}`" v-model="bank.account_no" :disabled="tourIsActive">
                                                        <span class="text-danger" >{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="bankInputField">
                                                    <label :for="`IBAN+${index}`">IBAN</label>
                                                    <input type="text" :id="`IBAN+${index}`" v-model="bank.IBAN" :disabled="tourIsActive">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="bankInputField">
                                                    <label :for="`swiftCode+${index}`">Swift Code</label>
                                                    <input type="text" :id="`swiftCode+${index}`" v-model="bank.swift_code" :disabled="tourIsActive">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="bankInputField">
                                                    <label :for="`routingNo+${index}`">Routing No.</label>
                                                    <input type="text" :id="`routingNo+${index}`" v-model="bank.routing_no" :disabled="tourIsActive">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="paymentMethodFormBtn">
                                        <div class="paymentSaveBtn">
                                            <Button id="bank-transfer-add-bank-btn" type="button" @click="addMoreBankHandle" :disabled="tourIsActive || bankTransferLoader">Add Bank</Button>
                                            <loaderBtn v-if="bankTransferLoader"/>
                                            <Button id="bank-transfer-save-btn" type="submit" :disabled="tourIsActive" v-else>Save</Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ValidationObserver>
                    </div>
                    <!-- Bank Transfer Ends Here -->
                </div>
            </div>
        </div>

        <!--vue tour-->
        <v-tour name="paymentModuleTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>
<script>
import {MessageBox, Message} from 'element-ui'
import _ from 'lodash';
import { mapGetters } from 'vuex';
import subHeader from "../../components/subHeader.vue";
import tutorialsAction from "../../components/tutorialsAction.vue";
import loaderBtn from "../../components/loaderBtn.vue";

export default {
    metaInfo() {
        return {
            title: 'E-Commerce | Payment Methods | YeetCommerce',
        };
    },
    data() {
        return {
            alfalahPaymenthMethod:[],
            alfalahPaymentGateway:{
                merchantId: '',
                merchantChannelId: '',
                merchantreturnUrl: '',
                merchantStoreId: '',
                merchantHash: '',
                merchantUsername: '',
                merchantPassword: '',
                merchantKeyOne:'',
                merchantKeyTwo:'',
                isBin:0,
            },
            tempAlfalahPaymenthMethod:[],
            stripePaymentMethod:[],
            stripePaymentGateway:{
                publishableKey:'',
                secretKey:'',
            },
            tempStripePaymentMethod:[],
            cashOnDelivery:[],
            bankTransferPaymentMethod:[],
            tempBankTransferPaymentMethod:[],
            bankTransferBanksList:[],
            paymentMethods:[],
            paymentMethodsLoader:false,
            alfalahLoader:false,
            stripeLoader:false,
            bankTransferLoader:false,
            
            //vue-tour
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //payment module steps
                //COD payment method steps
                {
                    target: '#COD-step',
                    content: `<strong>Cash On Delivery</strong><br>COD payment method is available for the user.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#COD-step-status',
                    content: `<strong>Active/Disabled</strong><br>Status of the COD payment method can be changed using this option.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                //Alfalah payment method steps
                {
                    target: '#Alfalah-step',
                    content: `<strong>Alfalah Payment Gateway</strong><br>Bank Alfalah payment gateway is available for the user.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#alfalah-step-status',
                    content: `<strong>Active/Disabled</strong><br>Status of the Alfalah payment gateway can be changed using this option.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#Alfalah-form',
                    content: `<strong>Alfalah Payment Gateway</strong><br>Alfalah payment gateway can be enabled by filling the information in the available form.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#alfalah-merchant-username',
                    content: `<strong>Merchant Username</strong><br>Merchant username can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#alfalah-merchant-password',
                    content: `<strong>Merchant Password</strong><br>Merchant password can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#alfalah-channel-id',
                    content: `<strong>Channel ID</strong><br>Channel Id can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#alfalah-merchant-id',
                    content: `<strong>Merchant ID</strong><br>Merchant Id can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#alfalah-store-id',
                    content: `<strong>Store ID</strong><br>Store Id can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#alfalah-merchant-hash',
                    content: `<strong>Merchant Hash</strong><br>Merchant hash can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#alfalah-key-one',
                    content: `<strong>Key One</strong><br>Key one can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#alfalah-key-two',
                    content: `<strong>Key Two</strong><br>Key two can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#merchantReturnUrl',
                    content: `<strong>Return URL</strong><br>You've to use this return URL in Alfalah Merchant portal to generate the credentials.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#alfalah-save-btn',
                    content: `<strong>Save Details</strong><br>Alfalah payment gateway details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                //Stripe payment method steps
                {
                    target: '#stripe-step',
                    content: `<strong>Stripe Payment Gateway</strong><br>Stripe payment gateway is available for the user.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#stripe-step-status',
                    content: `<strong>Active/Disabled</strong><br>Status of the Stripe payment gateway can be changed using this option.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#stripe-form',
                    content: `<strong>Stripe Payment Gateway</strong><br>Stripe payment gateway can be enabled by filling the information in the available form.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#stripe-publishable-key',
                    content: `<strong>Stripe Publishable Key</strong><br>Stripe publishable key can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#stripe-secret-key',
                    content: `<strong>Stripe Secret Key</strong><br>Stripe secret key can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#stripe-save-btn',
                    content: `<strong>Save Details</strong><br>Stripe payment gateway details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                //Bank transfer payment method steps
                {
                    target: '#bank-transfer-step',
                    content: `<strong>Bank Transfer</strong><br>Bank Transfer Details can be added using this form.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#bank-transfer-step-status',
                    content: `<strong>Active/Disabled</strong><br>Status of the bank transfer payment method can be changed using this option.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#bank-transfer-form',
                    content: `<strong>Bank Transfer</strong><br>Bank details can be added by filling the information in the available form.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#bank-transfer-add-bank-btn',
                    content: `<strong>Add Bank Details</strong><br>More bank details can be added by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#bank-transfer-save-btn',
                    content: `<strong>Save Details</strong><br>Bank transfer details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
            ],
            tourIsActive:false
        }

    },
    components:{
        subHeader,
        tutorialsAction,
        loaderBtn
    },
    computed:{
    },
    methods:{
        async validate(form){

            if(form == 'alfalah'){

                this.$refs.alfalahObserver.validate().then((success) => {
                    
                    if(!success){

                        const errors = Object.entries(this.$refs.alfalahObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                        this.$refs.alfalahObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                    }else{

                        this.updatePaymentMethod('alfalah');
                        
                    }

                });
            }
            
            if(form == 'stripe'){

                this.$refs.stripeObserver.validate().then((success) => {

                    if(!success){

                        const errors = Object.entries(this.$refs.stripeObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                        this.$refs.stripeObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                    }else{

                        this.updatePaymentMethod('stripe');

                    }

                });

            }

            if(form == 'bankTransfer'){

                this.$refs.bankTransferObserver.validate().then((success) => {

                    if(!success){

                        const errors = Object.entries(this.$refs.bankTransferObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                        this.$refs.bankTransferObserver.refs[errors[0]["key"]].$el.scrollIntoView({behavior: "smooth", block: "center" });

                        errors.forEach((error) =>{

                            let value = null;

                            if(error.value.length > 0){

                                value = error.key.split("_");

                                if(!this.bankTransferBanksList[value[1]].isActive){

                                    this.bankTransferBanksList[value[1]].isActive = 1

                                }

                            }

                        });

                    }else{

                        this.updatePaymentMethod('bankTransfer');

                    }

                });
                
            }

        },
        async updatePaymentMethod(paymentMethod){

            let formData = new FormData()

            if(paymentMethod == 'alfalah'){

                formData.append('id',this.alfalahPaymenthMethod.id);
                formData.append('isActive',this.alfalahPaymenthMethod.isActive ? 1 : 0);

                //data
                formData.append('merchantUsername',this.alfalahPaymentGateway.merchantUsername);
                formData.append('merchantPassword',this.alfalahPaymentGateway.merchantPassword);
                formData.append('merchantChannelId',this.alfalahPaymentGateway.merchantChannelId);
                formData.append('merchantId',this.alfalahPaymentGateway.merchantId);
                formData.append('merchantStoreId',this.alfalahPaymentGateway.merchantStoreId);
                formData.append('merchantHash',this.alfalahPaymentGateway.merchantHash);

                formData.append('merchantKeyOne',this.alfalahPaymentGateway.merchantKeyOne);
                formData.append('merchantKeyTwo',this.alfalahPaymentGateway.merchantKeyTwo);

                this.alfalahLoader = true;
            }

            if(paymentMethod == 'bankTransfer'){
                
                let activeBanks = this.bankTransferBanksList.filter((bank) => {

                    if(bank.isActive == 1){
                        return bank;
                    }

                });

                if(activeBanks.length == 0){

                    this.$notify({
                        type: 'error',
                        title: "Changes could not be saved.",
                        message: "It is necessary to have at least one active bank.",
                    });

                    return
                }

                formData.append('id',this.bankTransferPaymentMethod.id);
                formData.append('banks',JSON.stringify(this.bankTransferBanksList));
                formData.append('isActive',this.bankTransferPaymentMethod.isActive ? 1 : 0);
                this.bankTransferLoader = true;

            }

            if(paymentMethod == 'stripe'){

                formData.append('id',this.stripePaymentMethod.id);
                formData.append('isActive',this.stripePaymentMethod.isActive ? 1 : 0);

                //data
                formData.append('publishableKey',this.stripePaymentGateway.publishableKey);
                formData.append('secretKey',this.stripePaymentGateway.secretKey);

                MessageBox.confirm(
                    'Orders must meet a minimum total of 50 cents in USD to use Stripe as the payment method.',
                    'Stripe Payment Dependency',
                    {
                        type: 'info',
                        confirmButtonText: 'Acknowledge',
                        showCancelButton:true,
                        showClose:true,
                        closeOnClickModal:false,
                        closeOnPressEscape:false,
                        beforeClose: async (action, instance, done) => {

                            if(action === 'confirm'){

                                instance.confirmButtonLoading = true
                                instance.confirmButtonText = 'Loading...'

                                try{
                                    let res = await this.$axios.post('updatePaymentMethod',formData);
                                    if(res.data.status_code == '1016'){

                                        this.$notify({
                                            type: 'success',
                                            title: 'Success',
                                            message: res.data.message,
                                        });

                                    }
                                }catch(error){

                                    if(error.response){

                                        if(error.response.data.error.includes('Invalid') || 
                                        error.response.data.error.includes('least one active bank')){

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.error,
                                            });

                                        }else{

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.message,
                                            });

                                        }
                                    }else{

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.message,
                                        });

                                    }

                                }finally{

                                    instance.confirmButtonLoading = false
                                    instance.confirmButtonText = 'Acknowledge'

                                    done();

                                    this.stripeLoader = false;
                                    this.alfalahLoader = false;
                                    this.bankTransferLoader = false;

                                    this.loadPaymentMethods();

                                }
                            }else{

                                done();

                            }

                        },

                    }

                ).then(() => {

                }).catch(() => {

                    this.loadPaymentMethods();

                })

            }else{

                try{
                    let res = await this.$axios.post('updatePaymentMethod',formData);
                    if(res.data.status_code == '1016'){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });

                    }
                }catch(error){

                    if(error.response){

                        if(error.response.data.error.includes('Invalid') || 
                        error.response.data.error.includes('least one active bank')){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error,
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }
                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    this.stripeLoader = false;
                    this.alfalahLoader = false;
                    this.bankTransferLoader = false;

                    this.loadPaymentMethods();

                }

            }

        },

        async toggleProceedableHandle(form){

            if(form == 'COD'){

                this.paymentMethodStatusToggle(form);

            }

            if(form == 'alfalah'){

                if(this.alfalahPaymenthMethod.isActive){

                    this.$refs.alfalahObserver.validate().then((success) => {

                        if(!success){

                            const errors = Object.entries(this.$refs.alfalahObserver.errors)
                            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                            this.$refs.alfalahObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

                            this.$notify({
                                type: 'info',
                                title: 'Information Missing',
                                message: "Kindly enter required information in the payment method and save it to enable it.",
                            });

                            this.$refs.alfalahObserver.reset();

                        }else{

                            if(this.alfalahPaymenthMethod.isActive != this.tempAlfalahPaymenthMethod.isActive){

                                this.paymentMethodStatusToggle(form);

                            }

                        }

                    });

                }else{

                    if(this.alfalahPaymenthMethod.isActive != this.tempAlfalahPaymenthMethod.isActive){

                        this.paymentMethodStatusToggle(form);

                    }

                }

            }

            if(form == 'stripe'){

                if(this.stripePaymentMethod.isActive){

                    this.$refs.stripeObserver.validate().then((success) => {

                        if(!success){

                            const errors = Object.entries(this.$refs.stripeObserver.errors)
                            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                            this.$refs.stripeObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

                            this.$notify({
                                type: 'info',
                                title: 'Information Missing',
                                message: "Kindly enter required information in the payment method and save it to enable it.",
                            });

                            this.$refs.stripeObserver.reset();

                        }else{

                            if(this.stripePaymentMethod.isActive != this.tempStripePaymentMethod.isActive){

                                this.paymentMethodStatusToggle(form);

                            }

                        }

                    });

                }else{

                    if(this.stripePaymentMethod.isActive != this.tempStripePaymentMethod.isActive){

                        this.paymentMethodStatusToggle(form);

                    }

                }

            }

            if(form == 'bank-transfer'){

                if(this.bankTransferPaymentMethod.isActive){

                    this.$refs.bankTransferObserver.validate().then((success) => {

                        if(!success){

                            const errors = Object.entries(this.$refs.bankTransferObserver.errors)
                            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                            this.$refs.bankTransferObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

                            this.$notify({
                                type: 'info',
                                title: 'Information Missing',
                                message: "Kindly enter required information in the payment method and save it to enable it.",
                            });

                            this.$refs.bankTransferObserver.reset();

                        }else{

                            if(this.bankTransferPaymentMethod.isActive != this.tempBankTransferPaymentMethod.isActive){

                                this.paymentMethodStatusToggle(form);

                            }

                        }

                    });

                }else{

                    if(this.bankTransferPaymentMethod.isActive != this.tempBankTransferPaymentMethod.isActive){

                        this.paymentMethodStatusToggle(form);

                    }

                }

            }

        },
        async paymentMethodStatusToggle(form){

            let formData = new FormData();

            if(form == 'COD'){

                formData.append('id',this.cashOnDelivery.id);
                formData.append('isActive',this.cashOnDelivery.isActive ? 1 : 0);

            }

            if(form == 'alfalah'){

                formData.append('id',this.alfalahPaymenthMethod.id);
                formData.append('isActive',this.alfalahPaymenthMethod.isActive ? 1 : 0);

            }

            if(form == 'stripe'){

                formData.append('id',this.stripePaymentMethod.id);
                formData.append('isActive',this.stripePaymentMethod.isActive ? 1 : 0);

            }

            if(form == 'bank-transfer'){

                formData.append('id',this.bankTransferPaymentMethod.id);
                formData.append('isActive',this.bankTransferPaymentMethod.isActive ? 1 : 0);

            }

            try{
                let res = await this.$axios.post('togglePaymentMethod',formData);
                if(res.data.status_code == '1600' || res.data.status_code == '1601'){
                    
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: 'Payment method has been '+ res.data.message,
                    });

                }

            }catch(error){

                if(error.response){

                    if(error.response.data.error.includes('At least one')){
                        
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.loadPaymentMethods();

            }

        },
        
        addMoreBankHandle(){

            let tempBank = {
                bank_name:"",
                account_no:"",
                account_title:"",
                IBAN:"",
                swift_code:"",
                routing_no:"",
                isActive:1
            }

            this.bankTransferBanksList.push(tempBank);

            this.$refs.bankTransferObserver.reset();

        },
        removeBankHandle(bank,index){

            if(this.bankTransferBanksList.length == 1){

                this.bankTransferBanksList.splice(index,1);

                let tempBank = {
                    bank_name:"",
                    account_no:"",
                    account_title:"",
                    IBAN:"",
                    swift_code:"",
                    routing_no:"",
                    isActive:1
                }

                this.bankTransferBanksList.push(tempBank);

                this.$refs.bankTransferObserver.reset();

            }else{

                this.bankTransferBanksList.splice(index,1);

            }

        },
        bankStatusChangeHandle(bank,index){

            let activeBanks = this.bankTransferBanksList.filter((bank) => {

                if(bank.isActive){

                    return bank

                }

            });

            if(bank.isActive){

                this.bankTransferBanksList[index].isActive = 1;

            }else{

                this.bankTransferBanksList[index].isActive = 0;

            }

        },
        //tour methods
        startPaymentModuleTour(){

            this.$tours.paymentModuleTour.start();

            this.tourIsActive = true;

        },
        handleNextStep(currentStep){

            if(this.$tours.paymentModuleTour.currentStep + 1 == 9){

                this.alfalahPaymenthMethod.isActive = true;

            }

            if(this.$tours.paymentModuleTour.currentStep + 1 == 14){

                this.stripePaymentMethod.isActive = true;

            }

            if(this.$tours.paymentModuleTour.currentStep + 1 == 28){

                this.bankTransferPaymentMethod.isActive = true;

            }

        },
        handlePreviousStep(currentStep){

            if(this.$tours.paymentModuleTour.currentStep == 9){

                this.alfalahPaymenthMethod.isActive = false;

            }

            if(this.$tours.paymentModuleTour.currentStep == 14){

                this.stripePaymentMethod.isActive = false;

            }

            if(this.$tours.paymentModuleTour.currentStep == 28){

                this.bankTransferPaymentMethod.isActive = false;

            }

        },
        handleTourStop(){

            if(this.$tours.paymentModuleTour && this.$tours.paymentModuleTour.currentStep != -1){

                this.loadPaymentMethods();

                this.tourIsActive = false

            }

        },
        handleTourFinish(){

            this.loadPaymentMethods();

            this.tourIsActive = false;


        },
        handleTourSkip(){

            if(this.$tours.paymentModuleTour && this.$tours.paymentModuleTour.currentStep != -1){

                this.loadPaymentMethods();

                this.tourIsActive = false;

            }

        },
        
        async loadPaymentMethods(){

            this.paymentMethodsLoader = true;
            try{
                let res = await this.$axios.get('/getPaymentMethods');
                if(res.data.status_code == '1015'){

                    this.paymentMethodsLoader = false;
                    
                    res.data.payment_methods.forEach(method => {

                        if(method.type == "Cash on Delivery"){

                            this.cashOnDelivery = method;

                        }

                        if(method.type == 'Alfalah Payment Gateway'){

                            this.alfalahPaymenthMethod = method;
                            this.tempAlfalahPaymenthMethod = _.cloneDeep(method);

                            let gateway = JSON.parse(this.alfalahPaymenthMethod.data);

                            this.alfalahPaymentGateway = gateway;

                        }
                        if(method.type == 'Stripe Payment Gateway'){

                            this.stripePaymentMethod = method;
                            this.tempStripePaymentMethod = _.cloneDeep(method);
                            
                            let gateway = JSON.parse(this.stripePaymentMethod.data);

                            this.stripePaymentGateway = gateway;

                        }   
                        if(method.type == 'Bank Transfer'){

                            this.bankTransferPaymentMethod = method;
                            this.tempBankTransferPaymentMethod = _.cloneDeep(method);

                            this.bankTransferBanksList = JSON.parse(method.data);

                        }

                    });

                }
            }catch(error){

                this.paymentMethodsLoader = false;

                this.$message({
                    type: 'error',
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });

            }
        }
    },
    async beforeMount(){

        //loading payment methods
        this.loadPaymentMethods();

    }
}
</script>
<style scoped>
    .bankingFormField .row{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .paymentSaveBtn.stripe{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .paymentSaveBtn.stripe .paymentNote{
        display: flex;
        align-items: flex-end;
    }
</style>